<template>
	<div class="card-item x" v-if="layout === 'x'">
		<div class="left" :style="{ width: `calc(${contentWidth} - ${flexible.px2rem(10)}rem)` }">
			<div class="ci-title">{{ title }}</div>
			<div class="ci-description">{{ description }}</div>
		</div>
		<img :src="icon" alt="">
	</div>
	<div class="card-item x-revert" v-else-if="layout === 'x-revert'">
		<img :src="icon" alt="">
		<div class="left" :style="{ width: `calc(${contentWidth} - ${flexible.px2rem(10)}rem)`, paddingLeft: `calc(100% - ${contentWidth} - ${flexible.px2rem(10)}rem)` }">
			<div class="ci-title">{{ title }}</div>
			<div class="ci-description">{{ description }}</div>
		</div>
	</div>
	<div class="card-item y" v-else>
		<img :src="icon" alt="">
		<div class="ci-title">{{ title }}</div>
		<div class="ci-description">{{ description }}</div>
	</div>
</template>

<script>
export default {
	name: "card-item",
	data: function () {
		return {
			flexible: window.flexible
		}
	},
	props: {
		icon: {
			type: String,
			default: ""
		},
		title: {
			type: String,
			default: ""
		},
		description: {
			type: String,
			default: ""
		},
		layout: {
			type: String,
			default: "x"
		},
		contentWidth: {
			type: [Number, String],
			default: '70%'
		}
	},
	mounted () {
		console.log(`calc(100% - ${this.contentWidth} - ${flexible.px2rem(10)}rem`);
	}
}
</script>

<style scoped lang="scss">
.card-item {
	position: relative;
	width: 100%;
	height: 100%;
	background: #FFFFFF;
	box-shadow: 5px 5px 70px 1px #E8E8E8;
	padding: 40px;
	box-sizing: border-box;
	z-index: 0;
	img {
		object-fit: scale-down;
		z-index: -1;
	}
	.ci-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: bold;
		font-size: 18px;
		color: #333333;
	}
	.ci-description {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: #666666;
		line-height: 28px;
	}
	&.y {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		img {
			max-width: 100%;
		}
		.ci-title {
			margin-top: 26px;
		}
		.ci-description {
			margin-top: 17px;
		}
	}
	&.x {
		.left {
			width: calc(70% - 10px);
		}
		.ci-description {
			margin-top: 17px;
		}
		img {
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}
	&.x-revert {
		.left {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.ci-description {
			margin-top: 20px;
		}
		img {
			object-fit: cover;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}
}
</style>
