import flexAble from './utils/lib-flexable.js';
import utils from './utils/utils.js';
import './asset/common/common.css'
import 'normalize.css';
import 'animate.css';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from './router/index.js';
import store from './store/index.js';
import App from './App.vue';
import topNav from './components/top-nav.vue';
import stripTitle from './components/strip-title.vue';
import BlockTitle from "./components/block-title/index.vue";
import myPartner from './components/my-partner.vue';
import myFooter from './components/footer/index.vue';
import ScrollToTop from "./components/scroll-to-top.vue";
import FooterResolution from "./components/footer/footer-resolution.vue";
import caseList from "./components/case-list/index.vue";

window.utils = utils;

flexAble(window, window['lib'] || (window['lib'] = {}))
Vue.use(VueAxios, axios);

Vue.prototype.$axios = axios;
Vue.prototype.$bus = new Vue();

Vue.component('top-nav', topNav);
Vue.component('strip-title', stripTitle);
Vue.component('my-partner', myPartner);
Vue.component('my-footer', myFooter);
Vue.component('footer-resolution', FooterResolution)
Vue.component('scroll-to-top', ScrollToTop);
Vue.component('block-title', BlockTitle);
Vue.component('case-list', caseList);

console.log(process.env.BASE_URL);

new Vue({
	router,
	store,
	render: (h) => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#root');
