import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import json from '../../public/data.json'

Vue.use(Vuex);

function parseJson(target) {
	Object.keys(target).forEach(key => {
		const val = target[key];
		if (typeof val === 'string' || typeof val === 'number') {
			target[key] = ''
		} else if (Array.isArray(val) || typeof val === 'object') {
			parseJson(val)
		}
	})
}

parseJson(json)

export default new Vuex.Store({
	state: {
		// bannerIndex: '0',
		// bannerChildIndex: null,
		dataJSON: null
	},
	getters: {
		globalData(state) {
			const data = state.dataJSON
			if (data) {
				return data
			} else {
				return json
			}
		}
	},
	mutations: {
		setBannerIndex(state, payload) {
			state.bannerIndex = payload;
		},
		setBannerChildIndex(state, payload) {
			state.bannerChildIndex = payload;
		},
		setdataJSON(state, payload) {
			state.dataJSON = payload;
		}
	},
	actions: {
		async fetchData(context) {
			const data = await axios.get(window.origin + (process.env.BASE_URL || '/') + 'data.json');
			context.commit('setdataJSON', data.data || {});
		}
	},
	strict: process.env.NODE_ENV !== 'production'
})
