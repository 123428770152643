<template>
	<div class="pin-card" :class="{single: !title}">
		<img :src="icon" alt="">
		<div class="p-title" v-if="title">{{ title }}</div>
		<div class="p-sub">{{ sub }}</div>
	</div>
</template>

<script>
export default {
	name: "pin-card",
	props: {
		title: {
			type: String,
			default: ''
		},
		icon: {
			type: String,
			default: ''
		},
		sub: {
			type: String,
			default: ''
		},
	}
}
</script>

<style scoped lang="scss">
.pin-card {
	box-sizing: border-box;
	padding: 22px;
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: 1fr 1fr;
	column-gap: 16px;
	grid-template-areas:
		'a b'
		'a c';
	&.single {
		grid-template-areas: 'a b';
		.p-sub {
			align-self: center;
		}
	}
	img {
		grid-area: a;
		object-fit: scale-down;
	}
	.p-title {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 24px;
		color: #3384E8;
		align-self: flex-start;
	}
	.p-sub {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		align-self: flex-end;
	}
}
</style>
