<template>
	<div class="partner-container">
		<div class="main">
			<div class="title">
				<div class="title-value">{{ partner.title }}</div>
			</div>
			<div class="sub-title">{{ partner.subTitle }}</div>
			<div class="description">{{ partner.description }}</div>
			<div class="partner-list" ref="list">
				<div class="bg-black"></div>
				<div class="partner-item"
				     @click="goTo(item.link)" v-for="(item, i) in partner.list"
				     :key="i">
					<img class="logo" :src="getImg(item.logo)" :alt="item.name">
					<img class="logo hover" :src="getImg(item.logoHover)" :alt="item.name">
				</div>
				<div class="partner-item empty">
					<div class="more" @click="goTo('/about')">
						查看更多&nbsp;&nbsp;<img src="../asset/common/images/more-arrow.png" alt="箭头">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data: function () {
		return {
			partnerSize: {
				width: 260,
				height: 168,
				minWidth: 200,
				maxWidth: 320,
				gap: 60,
				count: 0,
				rate: 260 / 168
			},
		}
	},
	computed: {
		...mapGetters(['globalData']),
		partner () {
			return this.globalData.index.partner
		},
	},
	methods: {
		getImg (file) {
			return `${ window.origin }${ process.env.BASE_URL || '/' }${ file }`
		},
		goTo(route) {
			route = String(route)
			if (route === this.route) {
				return false
			} else if (route.startsWith('/')) {
				return this.$router.push(route)
			} else if (route.startsWith('http')) {
				window.open(route)
			}
		}
	},
}
</script>

<style scoped lang="scss">
$baseSize: 24;
@function px2rem($px) {
	@if calc($px / $baseSize * 0.75) <= 0.5 {
		@return 14px;
	}
	@return calc($px / $baseSize * 0.75rem);
}
.partner-container {
	padding-top: px2rem(100);
	background: white;
	position: relative;
	overflow: hidden;

	.title {
		display: flex;
		justify-content: flex-end;
		font-size: px2rem(48);
		font-weight: bold;
		color: #333333;

		.title-value {
			&::after {
				display: block;
				content: '';
				width: 100%;
				height: 15px;
				background: #0F52FB;
				margin-top: 6px;
			}
		}
	}

	.sub-title {
		font-size: px2rem(18);
		font-weight: 400;
		color: #999999;
		display: flex;
		justify-content: flex-end;
		padding: px2rem(26) 0 px2rem(50) 0;
	}

	.description {
		font-size: px2rem(24);
		font-weight: 500;
		color: #666666;
		display: flex;
		justify-content: flex-end;
	}

	.bg-black {
		position: absolute;
		top: -140px;
		left: 0;
		height: 200%;
		width: 300%;
		z-index: -1;
		transform: translateX(-40%) translateY(-1%) rotate(5deg);
		background: #1A1A1A;
	}

	.partner-list {
		min-height: 500px;
		margin-top: 250px;
		padding-bottom: 50px;/* no */
		position: relative;
		z-index: 1;
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-auto-rows: 180px;
		gap: 40px;

		.partner-item {
			width: 100%;
			height: 100%;
			background: #1A1A1A;
			border: 1px solid #0F52FB;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			.logo.hover {
				display: none;
			}
			&:hover {
				background: white;
				.logo {
					display: none;
				}
				.logo.hover {
					display: block;
				}
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				height: 100%;
				right: -10px;
				top: -10px;
				background: #0F52FB;
				z-index: -1;
			}

			&.empty {
				background: none;
				border: none;

				&::before {
					display: none;
				}
			}
		}
	}

	.more {
		background: #0F52FB;
		border-radius: 4px 4px 4px 4px;
		padding: 10px 20px;/* no */
		white-space: nowrap;
		opacity: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 24px;
		font-weight: 400;
		color: #FFFDFD;
		cursor: pointer;
		box-sizing: border-box;
		img {
			width: 32px!important;
			height: 12px!important;
		}
	}
}
@media screen and (max-width: 1440px) {
	.partner-container {
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			grid-template-columns: repeat(4, 1fr);
		}
	}
}
@media screen and (max-width: 1100px) {
	.partner-container {
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
@media screen and (max-width: 767px) {
	.partner-container {
		.title {
			font-size: 24px;
		}
		.more {
			padding: 5px 10px;/* no */
		}
		.partner-list {
			gap: 20px;
			margin-top: 80px;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 120px;
			min-height: unset;
			padding-bottom: 20px;
			.bg-black {
				top: -50px;
			}
		}
		.more {
			padding: 10px 20px;/* no */
			white-space: nowrap;
			opacity: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			font-weight: 400;
			color: #FFFDFD;
			cursor: pointer;
			box-sizing: border-box;
			img {
				width: 15px!important;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.partner-container {
		.partner-list {
			gap: 20px;
			margin-top: 80px;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: 80px;
			min-height: unset;
			padding-bottom: 20px;
		}
	}
}
</style>
