<template>
	<div class="footer-container">
		<img class="relative-center" src="./assets/footer-title.png" alt="">
		<div class="grid-box">
			<div class="left">

				<div class="qrcode-box">
					<img class="qrcode" src="./assets/footer-qrcode.png">
					<div class="qr-title">微信公众号</div>
					<div class="qr-explain">扫一扫关注我们</div>
				</div>

				<div class="company-info">
					<div class="container-box">
						<div class="ci-title">全国服务热线：</div>
						<div class="ci-phone">{{ this.footer.contact.tel }}</div>
					</div>
					<div class="container-box">
						<div class="ci-mail">电子邮箱: {{ this.footer.contact.email }}</div>
						<div class="ci-mail" style="margin-top: 24px;">公司地址: {{ this.footer.contact.address }}</div>
					</div>
				</div>

			</div>
			<div class="right">
				<div class="render-list" v-for="t in list" :key="t.name">
					<div class="top-title"  @click="goTo(t.path)">{{ t.name }}</div>
					<div class="r-children">
						<div class="r-child" v-for="c in t.children" :key="c.name" @click="goTo(c.path)" :class="{ selected: isSelected(c) }">{{ c.name }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="copyright-box">
			<span>{{ globalData.common.footer.copyright }}</span>
			<a class="copyright" target="_blank" href="https://beian.miit.gov.cn/">
				<img src="@/asset/common/images/beian.png" alt="">
				{{ globalData.common.footer.record }}
			</a>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	data () {
		return {
		}
	},
	computed: {
		...mapGetters(['globalData']),
		list() {
			return this.globalData?.common?.navs?.find?.(t => t.name === '解决方案')?.route
		},
		footer () {
			return this.globalData.common.footer
		},
		person() {
			return this.footer.contact.person.filter(which => !which.disabled)
		},
		route () {
			return this.$route.path
		}
	},
	created () {
		console.log(this.globalData);
	},
	methods: {
		goTo(route) {
			const source = route;
			route = String(route)
			if (route === this.route) {
				return false
			} else if(Array.isArray(source)){
				return this.goTo(source[0].path)
			} else if (route.startsWith('/')) {
				return this.$router.push(route)
			} else if (route.startsWith('http')) {
				window.open(route)
			}
		},
		isSelected(item) {
			if (Array.isArray(item.route)) {
				return item.route.some(each => each.path === this.route)
			} else {
				return item.route === this.route
			}
		}
	},
}
</script>

<style scoped lang="scss">
.footer-container {
	width: 100%;
	padding-top: 20px;
	background-image: url("./assets/footer-bg.png");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-color: #323537;
	* {
		cursor: default;
	}
	.grid-box {
		margin-top: 30px;
		display: grid;
		gap: 50px;
		grid-template-columns: auto auto;
		justify-content: center;
	}
	.left {
		display: grid;
		gap: 40px;
		grid-template-columns: 125px 295px;
		.qrcode-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			white-space: nowrap;
		}
		.company-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}
		.qr-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 18px;
			color: #FFFFFF;
			margin-top: 24px;
			text-stroke: 1px rgba(0,0,0,0);
			text-align: left;
			font-style: normal;
			text-transform: none;
			-webkit-text-stroke: 1px rgba(0,0,0,0);
		}
		.qr-explain {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 16px;
			color: rgba(255,255,255,0.6);
			margin-top: 16px;
			text-stroke: 1px rgba(0,0,0,0);
			text-align: left;
			font-style: normal;
			text-transform: none;
			-webkit-text-stroke: 1px rgba(0,0,0,0);
		}
		.ci-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bold;
			font-size: 14px;
			color: white;
		}
		.ci-phone {
			margin-top: 30px;
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: bolder;
			font-size: 24px;
			color: rgba(78, 153, 253, 1);
		}
		.ci-mail {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 400;
			font-size: 14px;
			color: rgba(255,255,255,0.6);
			line-height: 24px;
		}
	}
	.right {
		display: grid;
		grid-template-columns: repeat(5, auto);
		gap: 60px;
		.top-title {
			font-family: Source Han Sans CN, Source Han Sans CN;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF;
			cursor: pointer;
		}
		.r-children {
			margin-top: 24px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			.r-child {
				font-family: Source Han Sans CN, Source Han Sans CN;
				font-weight: 400;
				font-size: 16px;
				color: rgba(255,255,255,0.6);
				cursor: pointer;
				transition: color .2s;
				&:hover {
					color: rgba(78, 153, 253, 1);
				}
			}
		}
	}
	.copyright-box {
		width: 100%;
		margin-top: 40px;
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 14px;
		color: rgba(255,255,255,0.4);
		border-top: 1px solid rgba(255,255,255,0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 24px 0;

		img {
			width: 20px;
			/* no */
			height: 22px;
			/* no */
		}
	}

	.copyright {
		margin-left: 30px;
		color: #707070;
		display: inline-flex;
		align-items: center;
		column-gap: 5px;
	}

	.copyright:hover {
		color: gray;
	}
}
</style>
