<template>
	<div class="car-monitor">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<card-list class="card-list-no-padding" gap="106" style="margin-top: 80px;" :list="block2"></card-list>
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<img class="b3-img" src="./assets/cm-b3.png" alt="">
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title4"></block-title>
				<case-list :list="block3"></case-list>
			</div>
		</div>
		<footer-resolution style="background-image: none" :data="static.title5"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import CardList from "../../components/card-list/index.vue";
import { mapGetters } from "vuex";

export default {
	name: "car-monitor",
	components: { CardList },
	data: function () {
		return {
			flexible: window.flexible,
			defines: {
				block1: [
					{
						title: '高精地图可视化',
						description: '定制开发带有业务场景的高精度专属地图，快速加载并与公共地图无缝拼接.',
						icon: require('./assets/cm-b1-0.png'),
						layout: 'y'
					},
					{
						title: '高精度实时位置查看',
						description: '可实时查看精确到车道的车辆位置信息，避免因定位偏差产生的误报等错误信息。',
						icon: require('./assets/cm-b1-1.png'),
						layout: 'y'
					},
					{
						title: '车辆历史轨迹查询',
						description: '支持车辆历史轨迹在高精度地图上的可视化展示，轨迹精确至车道级，真实还原车辆行驶信息',
						icon: require('./assets/cm-b1-2.png'),
						layout: 'y'
					},
					{
						title: '车辆禁行区域管理',
						description: '基于电子围栏技术，设置禁行区、禁停区域，如车辆违规进入可触发报警。',
						icon: require('./assets/cm-b1-3.png'),
						layout: 'y'
					},
					{
						title: '智能统计管理',
						description: '基于云计算平台，对系统登记的车辆进行任务统计、里程统计、报警信息统计等功能。',
						icon: require('./assets/cm-b1-4.png'),
						layout: 'y'
					},
					{
						title: '驾驶行为监控',
						description: '支持车辆历史轨迹在高精度地图上的可视化展示，轨迹精确至车道级，真实还原车辆行驶信息',
						icon: require('./assets/cm-b1-5.png'),
						layout: 'y'
					},
				],
				block2: [
					{
						title: '更加安全有效',
						description: '有效的车辆智能监管能够通过改善驾驶习惯、提前预警等能力，减少车辆事故发生。',
						icon: require('./assets/cm-b2-0.png'),
					},
					{
						title: '提升管理效率',
						description: '车辆智能监管方案，基于高精地图及位置数据，进行路线规划与配置、车队可视化管理、辅助优化司机驾驶行为，为指挥调度提供依据',
						icon: require('./assets/cm-b2-1.png'),
					},
					{
						title: '智能化管理',
						description: '基于车辆智能监管方案，在电脑或手机APP上，开展运输任务管理、路径智能规划、信息查询等，提高管理水平。',
						icon: require('./assets/cm-b2-2.png'),
					},
				],
				block3: [
					{
						name: '智慧水利',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-water-conservancy'),
						img: require('./assets/cm-b4-0.png')
					},
					{
						name: '智慧矿山',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-quarry'),
						img: require('./assets/cm-b4-1.png')
					},
					{
						name: '智慧港口',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-port'),
						img: require('./assets/cm-b4-2.png')
					},
					{
						name: '智慧交通',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-traffic'),
						img: require('./assets/cm-b4-3.png')
					},
					{
						name: '智慧城市',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city'),
						img: require('./assets/cm-b4-4.png')
					},
					{
						name: '智慧桥梁',
						button: '了解更多',
						onClick: () => this.$router.push('/smart-city/bridge-monitor'),
						img: require('./assets/cm-b4-5.png')
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-quarry']['car-monitor']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner () {
			return require('./assets/sq-qm-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.car-monitor {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b3-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 1289px;
	}
}
</style>
