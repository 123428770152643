<template>
	<div class="pipe-gallery">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<div class="sp" style="margin-top: 80px;" v-html="static.p1"></div>
				<card-list style="margin-top: 40px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<img class="b2-img" style="margin-top: 40px;" src="./assets/pg-b2.png" alt="">
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<introduce-list style="margin-top: 80px;" :list="block2"></introduce-list>
			</div>
		</div>
		<introduce-carousel style="height: 850px" :list="block3.list" :title="block3.title" :en="block3.en" :sub="block3.sub"></introduce-carousel>
		<footer-resolution :data="static.title4"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";
import IntroduceList from "../../components/introduce-list/index.vue";
import IntroduceCarousel from "../../components/introduce-list/introduce-carousel.vue";

export default {
	name: "pipe-gallery",
	components: { IntroduceCarousel, IntroduceList, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '运用建设',
						description: '智慧管廊系统是运用物联网、现代传感技术、云计算、计算机通信技术、虚拟现实、人工智能等综合系统集成技术，对综合管廊内管线、环境、设备及运行进行实时监控、运营管理和安全预警。',
						icon: require('./assets/pg-b1-0.png'),
					},
					{
						title: '设备配置',
						description: '智慧管廊系统通过在地下管廊配置一定数量的传感器和报警设备，并通过控制总线将信号上传自动化监控终端，并通过光纤网络或无线通信传输到监控中心，控制中心对数据进行分析。',
						icon: require('./assets/pg-b1-1.png'),
					},
					{
						title: '城市覆盖',
						description: '具有覆盖范围广，维护频率低，设备种类多，地下环境恶劣，空气不流通等特点，很难依靠传统的方式进行排查，通过各类型的传感器的长期监测，能够在确保工作人员身体安全的同时，降低设备损坏率实现更高效的集中化管理。',
						icon: require('./assets/pg-b1-2.png'),
					},
				],
				block2: [
					{
						title: '顶层框架设计',
						en: 'Top level framework design',
						description: '因地制宜，发现问题解决问题，探究答案，帮助运维层、决策层完成智慧综合管廊顶层设计，建立解决问题的框架、流程，保证智慧管廊平台有高度、能落地、有创新、会说话。',
						icon: require('./assets/pg-b3-0.png'),
						height: 505,
					},
					{
						title: '全局化的数据对接',
						en: 'Global data integration',
						description: '专业化对接管廊各个硬件承载的业务数据，及其他相关领域数据，根据实际监控情况，制定完善的数据对接规则，实现多硬件数据的高效运转和实时共享交换。',
						icon: require('./assets/pg-b3-1.png'),
						height: 505,
					},
					{
						title: '数据应用',
						en: 'data application',
						description: '针对不同地区的城市管理现状，专业化设计专门的数据监控、分析、决策、预警等数据可视化中心，解决各类硬件管理中的难题。',
						icon: require('./assets/pg-b3-2.png'),
						height: 505,
					},
				],
				block3: {
					title: '应用展示',
					en: 'Application Display',
					sub: '综合性监控系统',
					list: [
						{
							title: '智慧管廊监控系统',
							sub: '智慧管廊监控系统',
							icon: require('./assets/pg-b4-0.png'),
							iconHover: require('./assets/pg-b4-0-hover.png'),
							iconImg: require('./assets/pg-b4-0-b.png'),
							bg: require('./assets/pg-b4-bg.png'),
							description: '智慧管廊监控系统是一个综合性非常强的管控系统。各系统之间由于技术体系不一，采用的标准各异，导致难以兼容互通，这不仅需要兼顾环境与设备监控、通信联络、地理信息等需求，而且还要兼顾灾难事故预警、安全防范等方面对图像监控的需求，同时还需要考虑报警、门禁等配套系统的集成以及与广播系统的联动，因此在这些系统的互联过程中必然会出现系统异构的信息孤岛问题。\n',
						},
						{
							title: '智慧管廊BIM系统',
							sub: '智慧管廊BIM系统',
							icon: require('./assets/pg-b4-1.png'),
							iconHover: require('./assets/pg-b4-1-hover.png'),
							iconImg: require('./assets/pg-b4-1-b.png'),
							bg: require('./assets/pg-b4-bg.png'),
							description: ' 综合管廊采用二三维一体化的设计方式，平面视图管廊表现为二维方式，转换视角，管线表现为三维方式，可以直观查看管廊内部入廊管线的分布情况、管廊各出口的位置和安装在管廊内部附属设施的位置。同时反映综合管廊本体与周围地形、地物、建构筑物的关系。该系统参照CAD三视图的展示方式，通过主视角度查看管线在管廊内部的空间分布情况，通过侧剖面观察管廊内各里程的实际情况。',
						},
						{
							title: '智慧管廊报警系统',
							sub: '智慧管廊报警系统',
							icon: require('./assets/pg-b4-2.png'),
							iconHover: require('./assets/pg-b4-2-hover.png'),
							iconImg: require('./assets/pg-b4-2-b.png'),
							bg: require('./assets/pg-b4-bg.png'),
							description: '预警与报警系统的功能是实现对综合管廊的全程监测，系统将预警和报警信息通过多功能基站及时、准确地传输到监控中心，实现灾情预警、报警、处理及疏散，同时通过广播系统，向综合管廊内的工作人员广播，使他们及时撤离现场，保证人身安全等功能。',
						}
					]
				}
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-city']['pipe-gallery']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner () {
			return require('./assets/sc-ll-banner.png')
		},
	}
}
</script>

<style scoped lang="scss">
.pipe-gallery {
	.use-bg {
		background: url("@/views/smart-water-conservancy/assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b2-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 601px;
	}
	.sp {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 400;
		font-size: 18px;
		color: #666666;
		line-height: 36px;
	}
}
</style>
