<template>
	<div class="water-environment">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: left;" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area pt-100px pb-80px">
			<div class="block-content">
				<block-title :data="static.title1"></block-title>
				<card-list style="margin-top: 80px;" :list="block1"></card-list>
			</div>
		</div>
		<div class="block-area use-bg pt-80px pb-40px">
			<div class="block-content">
				<block-title :data="static.title2"></block-title>
				<card-list style="margin-top: 80px;" :list="block2"></card-list>
				<img class="b2-img" style="margin-top: 40px;" src="./assets/we-b2-3.png" alt="">
			</div>
		</div>
		<div class="block-area pt-80px pb-80px">
			<div class="block-content">
				<block-title :data="static.title3"></block-title>
				<introduce-list :list="block3"></introduce-list>
			</div>
		</div>
		<footer-resolution :data="static.title4"></footer-resolution>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CardList from "../../components/card-list/index.vue";
import IntroduceList from "../../components/introduce-list/index.vue";

export default {
	name: "water-environment",
	components: { IntroduceList, CardList },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '数据多头管理',
						description: '缺少专门对数据管理进行监督和控制的组织，各部门关注数据的角度不一样，缺少一个组织从全局的视角对数据进行管理，导致无法建立统一的数据管理规程、标准等。',
						icon: require('./assets/we-b1-0.png'),
					},
					{
						title: '多系统分散建设',
						description: '没有规范统一的数据标准和数据模型，各部门站在各自的立场生产、使用和管理数据，使得数据分散在不同的部门和信息系统中，缺乏统一的数据规划、可信的数据来源和数据标准。',
						icon: require('./assets/we-b1-1.png'),
					},
					{
						title: '数据质量不高',
						description: '缺乏清晰的数据质量管控规范与标准；数据的自动采集尚未全面实现，处理过程存在人为干预问题，导致水务企业数据中存在大量的错误、遗漏和重复数据，影响数据的分析和应用。',
						icon: require('./assets/we-b1-2.png'),
					},
					{
						title: '数据全生命周期管理不完整',
						description: '数据的产生、使用、维护、备份到过时被销毁的数据生命周期管理规范和流程不完善；无信息化工具支撑数据生命周期状态的查询，未有效利用元数据管理。',
						icon: require('./assets/we-b1-3.png'),
					},
				],
				block2: [
					{
						title: '驾驶舱',
						description: '以数字地理信息为基础，构建三维数字孪生地图底板，辐射区域内主要河流、湖泊、水库、水雨情站、水质监测站等涉水要素，集成展现区域水域一张图，建立水环境监管“数字驾驶舱”。',
						icon: require('./assets/we-b2-0.png'),
					},
					{
						title: '治理端',
						description: '面向监管人员，通过治理端实时掌握设备运行状态、远程控制无人机自动巡河、监督河长巡查履职情况，辅以大数据精准分析，及时获取雨污混流、河道垃圾等异常预警并同步派单处置，打造全面高效的闭环管理体系。',
						icon: require('./assets/we-b2-1.png'),
					},
					{
						title: '服务端',
						description: '面向基层工作者和基层群众，为基层工作者打造简易便捷的统一工作入口，为社会公众提供可感知可参与可反馈的开放式治水平台。',
						icon: require('./assets/we-b2-2.png'),
					},
				],
				block3: [
					{
						title: '北斗+AI，精准治理',
						en: 'Beidou+AI, precise governance',
						description: '基于北斗高精度定位技术，实现无人化自动巡航和空-地-水位置联动，提升问题定位的准确性和管护效率；基于深度学习的视觉AI技术和水利场景算法识别库，降低人力投入，大幅提升巡检效率。',
						icon: require('./assets/we-b3-0.png'),
						height: 505,
					},
					{
						title: '闭环管理，高效协同',
						en: 'Closed loop management',
						description: '提供全方位、多类型、可定制的数据分析能力与智能预警机制，构造水环境治理问题“发现-派发-处置-反馈”的全闭环管理机制。',
						icon: require('./assets/we-b3-1.png'),
						height: 505,
					},
					{
						title: '全链路数据生态，提升治理能力',
						en: ' enhancing governance capabilities',
						description: '将涉水信息化系统全面打通，实现涉水数据全面汇聚与共享，提供分析、研判和决策依据，拓宽治理空间，提升治理能力。',
						icon: require('./assets/we-b3-2.png'),
						height: 300,
					},
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-water']['water-environment']
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		block3() {
			return window.deepMerge(this.defines.block3, this.static.block3)
		},
		banner() {
			return require('./assets/security-monitor-banner.png')
		},
	},
}
</script>

<style scoped lang="scss">
.water-environment{
	.use-bg {
		background: url("./assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
	.b2-img {
		object-fit: scale-down;
		margin: 0 auto;
		display: block;
		width: 100%;
		height: 1013px;
	}
}
</style>
