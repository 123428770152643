<template>
  <div>
    <top-nav :imgList="banner" white hideButton>
      <div class="en-bannerTxt">{{ globalData.service.en }}</div>
      <div class="bannerTxt">{{ globalData.service.txt }}</div>
    </top-nav>
    <div class="box content-limit">
      <div
        class="card-box flex space-between content-limit"
        style="margin-bottom: 80px"
      >
        <pin-card
          v-for="t in block1"
          :key="t.title"
          :title="t.title"
          :icon="t.icon"
          :sub="t.sub"
        ></pin-card>
      </div>
    </div>
    <div class="issue">
      <block-title
        :title="globalData.service.issue.top.title"
        :en="globalData.service.issue.top.en"
        :sub="globalData.service.issue.top.sub"
      ></block-title>
      <div class="display">
        <div class="left">
          <div class="lt item">
            <img class="img" src="@/asset/service/ic_service_05.png" alt="" />
            <div class="top-txt txt">
              <div class="title">
                {{ globalData.service.issue.display[0].title }}
              </div>
              <div class="brief">
                {{ globalData.service.issue.display[0].brief }}
              </div>
            </div>
          </div>
          <div class="lb item">
            <img class="img" src="@/asset/service/ic_service_07.png" alt="" />
            <div class="bot-txt txt">
              <div class="title">
                {{ globalData.service.issue.display[1].title }}
              </div>
              <div class="brief">
                {{ globalData.service.issue.display[1].brief }}
              </div>
            </div>
          </div>
        </div>
        <img class="middle" src="@/asset/service/ic_service_09.png" alt="" />
        <div class="right">
          <div class="rt item">
            <img class="img" src="@/asset/service/ic_service_06.png" alt="" />
            <div class="top-txt txt">
              <div class="title">
                {{ globalData.service.issue.display[2].title }}
              </div>
              <div class="brief">
                {{ globalData.service.issue.display[2].brief }}
              </div>
            </div>
          </div>
          <div class="rb item">
            <img class="img" src="@/asset/service/ic_service_08.png" alt="" />
            <div class="bot-txt txt">
              <div class="title">
                {{ globalData.service.issue.display[3].title }}
              </div>
              <div class="brief">
                {{ globalData.service.issue.display[3].brief }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="industry">
      <block-title
        :title="globalData.service.industry.top.title"
        :en="globalData.service.industry.top.en"
        :sub="globalData.service.industry.top.sub"
      ></block-title>
      <case-list :list="cases"></case-list>
    </div>
    <div class="service">
      <block-title
        :title="globalData.service.service.top.title"
        :en="globalData.service.service.top.en"
        :sub="globalData.service.service.top.sub"
      ></block-title>
      <div class="list content-limit">
        <div class="item" v-for="(item, index) of service" :key="index">
          <div class="content">
            <div class="outline">
              <img class="icon normal" :src="item.icon" alt="" />
              <img class="icon active" :src="item.actIcon" alt="" />
            </div>
            <div class="title">{{ item.title }}</div>
            <div class="en-title">{{ item.enTitle }}</div>
            <div class="brief">{{ item.brief }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="after">
      <block-title
        :title="globalData.service.after.top.title"
        :en="globalData.service.after.top.en"
        :sub="globalData.service.after.top.sub"
      ></block-title>
      <div class="list content-limit">
        <div class="item" v-for="(item, index) of after" :key="index">
          <img class="img" :src="item.img" alt="" />
          <div class="title">{{ item.title }}</div>
          <div class="brief">{{ item.brief }}</div>
        </div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PinCard from "../components/card-list/pin-card.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    block1() {
      return window.deepMerge(
        [
          {
            title: "申请方案体验",
            sub: "多端适配，应用场景交互体验",
            icon: require("@/asset/service/ic_service_02.png"),
          },
          {
            title: "支持私有化部署",
            sub: "专属服务器，适合安全性特殊的企业",
            icon: require("@/asset/service/ic_service_03.png"),
          },
          {
            title: "合作共赢",
            sub: "互惠合作，助力智慧产业化发展",
            icon: require("@/asset/service/ic_service_04.png"),
          },
        ],
        this.globalData.service.box.block1
      );
    },
    cases() {
      return window.deepMerge(
        [
          {
            name: "智慧水利",
            img: require("@/asset/smart-port/ic_port_38.png"),
            button: "了解更多",
          },
          {
            name: "智慧矿山",
            img: require("@/asset/smart-port/ic_port_34.png"),
            button: "了解更多",
          },
          {
            name: "智慧港口",
            img: require("@/asset/smart-port/ic_port_36.png"),
            button: "了解更多",
          },
          {
            name: "智慧交通",
            img: require("@/asset/smart-port/ic_port_33.png"),
            button: "了解更多",
          },
          {
            name: "智慧城市",
            img: require("@/asset/smart-port/ic_port_35.png"),
            button: "了解更多",
          },
          {
            name: "智慧桥梁",
            img: require("@/asset/smart-port/ic_port_37.png"),
            button: "了解更多",
          },
        ],
        this.globalData.service.industry.list
      );
    },
    service() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/service/ic_service_14.png"),
            actIcon: require("@/asset/service/ic_service_10.png"),
            title: "产品支持",
            enTitle: "product support",
            brief:
              "在合作伙伴数据相关行业应用开发过程中提供非保密性质的演示和测试软件（包括示例数据）、软件的版本免费定期升级等技术支持",
          },
          {
            icon: require("@/asset/service/ic_service_11.png"),
            actIcon: require("@/asset/service/ic_service_15.png"),
            title: "技术支持",
            enTitle: "technical support",
            brief:
              "提供对应产品的使用说明书、标准技术解决方案等全部标准资料支持，提供售前技术支持、项目现场实施支持、远程或现场售后服务支持",
          },
          {
            icon: require("@/asset/service/ic_service_12.png"),
            actIcon: require("@/asset/service/ic_service_16.png"),
            title: "市场支持",
            enTitle: "Marketing Support",
            brief:
              "授予正式签订合作协议的合作伙伴授权证书、赠予合作伙伴授权牌，共同参与策划编写国家标准或行业相关标准和申报科研项目课题",
          },
          {
            icon: require("@/asset/service/ic_service_13.png"),
            actIcon: require("@/asset/service/ic_service_17.png"),
            title: "销售支持",
            enTitle: "Sales Support",
            brief:
              "为合作伙伴提供2级销售支持，帮助合作伙伴完成针对项目的售前、售中及售后工作市场推广活动互动、市场资讯交流、培训、重大合作项目支持、例行巡访、各种跨部门的协调、争议处理等",
          },
        ],
        this.globalData.service.service.list
      );
    },
    after() {
      return window.deepMerge(
        [
          {
            img: require("@/asset/service/ic_service_18.png"),
            title: "提供专属保障",
            brief: "单独安排技术专家，保障快速响应",
          },
          {
            img: require("@/asset/service/ic_service_19.png"),
            title: "7*24*365远程运维",
            brief: "远程线上维护，服务不间断",
          },
          {
            img: require("@/asset/service/ic_service_20.png"),
            title: "透明公开",
            brief: "服务过程进度，线上公开透明化",
          },
          {
            img: require("@/asset/service/ic_service_21.png"),
            title: "主动排除故障",
            brief: "实现“救火式”向“预防式”产品运维转型",
          },
        ],
        this.globalData.service.after.list
      );
    },
  },
  components: {
    PinCard,
  },
  data() {
    return {
      banner: require("@/asset/service/ic_service_01.png"),
    };
  },
};
</script>
<style lang="scss" scoped>
.en-bannerTxt {
  font-size: 60px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  margin-bottom: 8px;
}
.bannerTxt {
  font-family: YouSheBiaoTiHei;
  font-size: 72px;
  color: white;
  margin-bottom: 24px;
  text-align: center;
}
.box {
  padding: 100px 0 80px;
}
.issue {
  padding: 80px 0 40px;
  background: url(@/asset/service/ic_service_22.png) no-repeat center/cover;
  .display {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    .item {
      position: relative;
    }
    .txt {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 44px 40px;
      box-sizing: border-box;
      .title {
        color: #4e99fd;
        font-size: 20px;
        margin-bottom: 24px;
      }
      .brief {
        color: #666666;
        font-size: 14px;
        line-height: 2;
      }
    }
    .img {
      width: 100%;
    }
    .top-txt {
    }
    .bot-txt {
      margin-top: 38px;
    }
    .left {
      .lt {
        width: 466px;
      }
      .lb {
        width: 466px;
      }
    }
    .middle {
      width: 560px;
    }
    .right {
      .rt {
        width: 466px;
      }
      .rb {
        width: 466px;
      }
    }
  }
}
.industry {
  padding: 100px 0 80px;
}
.service {
  padding: 80px 0 40px;
  background: url("@/asset/service/ic_service_22.png") no-repeat center/cover;
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 80px;
    .item {
      width: 288px;
      height: 452px;
      border-radius: 8px 8px 8px 8px;
      overflow: hidden;
      border: 1px solid rgba(51, 51, 51, 0.2);
      position: relative;
      .content {
        text-align: center;
        padding: 50px 24px 0;
        transition: all 0.5s;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        .outline {
          width: 70px;
          height: 70px;
          position: relative;
          margin: 0 auto;
          .icon {
            width: 36px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .active {
            display: none;
          }
          &::after {
            content: "";
            border: 1px solid rgba(51, 51, 51, 0.1);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: rotate(45deg);
          }
        }
        .title {
          font-size: 24px;
          color: #333333;
          margin-top: 20px;
          margin-bottom: 8px;
        }
        .en-title {
          font-size: 18px;
          color: #999999;
          margin-bottom: 16px;
        }
        .brief {
          font-size: 16px;
          color: #666666;
          line-height: 2;
        }
      }

      &:hover {
        .content {
          background: linear-gradient(167deg, #68a9ff 0%, #4e99fd 100%);
        }
        .title,
        .en-title,
        .brief {
          color: white;
        }
        .outline::after {
          border: 1px solid #ffffff;
        }
        .active {
          display: block !important;
        }
        .normal {
          display: none !important;
        }
      }
    }
  }
}
.after {
  padding: 80px 0;
  .list {
    display: flex;
    margin-top: 80px;
    justify-content: space-between;
    .item {
      text-align: center;
      .img {
        width: 160px;
        margin-bottom: 16px;
      }
      .title {
        margin-bottom: 20px;
        color: #333333;
        font-size: 24px;
      }
      .brief {
        color: #666666;
        font-size: 16px;
      }
    }
  }
}
</style>