<template>
  <div class="container">
    <top-nav :banner="globalData.index.banner" :imgList="banner" white isParent>
      {{ globalData.index.txt1
      }}<span class="banner-item-sp">{{ globalData.index.sp1 }}</span
      >{{ globalData.index.txt2
      }}<span class="banner-item-sp">{{ globalData.index.sp2 }}</span>
    </top-nav>
    <div class="about">
      <block-title
        :title="globalData.index.about.top.title"
        :en="globalData.index.about.top.en"
        :sub="globalData.index.about.top.sub"
      ></block-title>
      <div class="tabs content-limit">
        <div
          class="item"
          :class="{ active: index === abCurrentIndex }"
          v-for="(item, index) of globalData.index.about.tabs"
          :key="index"
          @click="abCurrentIndex = index"
        >
          <img class="img" :src="abtabs[index]" alt="" />
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="brief">{{ item.brief }}</div>
          </div>
        </div>
      </div>
      <template v-for="(item, index) of globalData.index.about.display">
        <div class="display" :key="index" v-if="abCurrentIndex === index">
          <div class="info">
            <div class="title">{{ item.title }}</div>
            <div
              class="info-item"
              v-for="(inneritem, innerindex) of item.infos"
              :key="innerindex"
            >
              {{ inneritem }}
            </div>
          </div>
          <img class="info-img" :src="infos[index]" alt="" />
        </div>
      </template>
    </div>
    <div
      class="drive"
      :style="{
        background: `url(${
          driveCurrentIndex === 0
            ? drivebg[0]
            : driveCurrentIndex === 1
            ? drivebg[1]
            : driveCurrentIndex === 2
            ? drivebg[2]
            : driveCurrentIndex === 3
            ? drivebg[3]
            : drivebg[4]
        }) no-repeat center/cover`,
      }"
    >
      <div class="en-title">{{ globalData.index.drive.enTitle }}</div>
      <div class="ch-title">{{ globalData.index.drive.chTitle }}</div>
      <div class="ch-brief">{{ globalData.index.drive.chBrief }}</div>
      <div class="content content-limit">
        <div class="left">
          <div
            class="item"
            v-for="(item, index) of drive"
            :key="index"
            @click="driveCurrentIndex = index"
          >
            <img
              class="item-icon"
              :src="item.left.activeImg"
              alt=""
              v-if="driveCurrentIndex === index"
            />
            <img class="item-icon" :src="item.left.img" alt="" v-else />
            <div class="item-txt">{{ item.left.txt }}</div>
          </div>

          <div
            class="item-strip"
            :style="{ top: 20 * driveCurrentIndex + '%' }"
          ></div>
        </div>
        <div class="right">
          <template v-for="(item, index) of drive">
            <div class="item" v-if="driveCurrentIndex === index" :key="index">
              <div class="title">{{ item.right.title }}</div>
              <div class="brief">
                {{ item.right.brief }}
              </div>
              <div class="more-button">
                {{ item.right.button }}
                <img
                  src="@/asset/index/ic_index_48.png"
                  class="more-icon"
                  alt=""
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="advantage">
      <block-title
        :title="globalData.index.advantage.top.title"
        :en="globalData.index.advantage.top.en"
        :sub="globalData.index.advantage.top.sub"
      ></block-title>
      <div class="content content-limit">
        <div class="left">
          <img class="icon" src="../asset/index/ic_index_29.png" alt="" />
          <div class="title">{{ globalData.index.advantage.left.title }}</div>
          <div class="en-title">
            {{ globalData.index.advantage.left.enTitle }}
          </div>
          <div class="brief">
            {{ globalData.index.advantage.left.brief }}
          </div>
        </div>
        <div class="right">
          <div class="item" v-for="(item, index) of advantage" :key="index">
            <img class="icon" :src="item.icon" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="en-title">{{ item.en }}</div>
            <div class="brief">
              {{ item.brief }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <block-title
        :title="globalData.index.case.top.title"
        :en="globalData.index.case.top.en"
        :sub="globalData.index.case.top.sub"
      ></block-title>
      <div class="content-limit">
        <div class="list">
          <div
            v-for="(item, index) of globalData.index.case.list"
            :key="index"
            class="item"
            :style="{
              background: `url(${casebg[index]})  no-repeat center/cover`,
            }"
          >
            <div class="hover">
              <div class="title">{{ item.title }}</div>
              <div class="sub">{{ item.sub }}</div>
              <div class="brief">
                {{ item.brief }}
              </div>
            </div>
          </div>
        </div>
        <div class="more-button">
          查看更多
          <img src="../asset/index/ic_index_48.png" class="more-icon" alt="" />
        </div>
      </div>
    </div>
    <div class="confirm">
      <block-title
        :title="globalData.index.confirm.top.title"
        :en="globalData.index.confirm.top.en"
        :sub="globalData.index.confirm.top.sub"
      ></block-title>
      <div class="content">
        <div class="swiper-list">
          <div
            class="swiper-item"
            v-for="(item, index) of swiper"
            :style="{
              transform: `translateX(${(index - swiperCurrentIndex) * 100}%)`,
            }"
            :key="index"
          >
            <img class="swiper-img" :src="item.img" alt="" />
            <div class="swiper-right">
              <div class="swiper-num">
                <span class="sp">
                  {{ `0${index + 1}` }}
                </span>
                <span class="count">
                  {{ `/0${swiper.length}` }}
                </span>
              </div>
              <div class="swiper-en">{{ item.en }}</div>
              <div class="swiper-title">{{ item.title }}</div>
              <div class="swiper-brief">{{ item.brief }}</div>
              <div class="swiper-more">
                了解更多
                <img
                  class="swiper-icon"
                  src="../asset/index/ic_index_49.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <img
          class="next"
          @click="next"
          src="../asset/index/ic_index_42.png"
          alt=""
        />
        <div class="indicator">
          <div
            class="indicator-item"
            :class="{ 'indicator-item-active': swiperCurrentIndex === index }"
            v-for="(item, index) of swiper"
            :key="index"
            @click="swiperCurrentIndex = index"
          ></div>
        </div>
      </div>
    </div>
    <div class="strip">
      <img class="bg" src="../asset/index/ic_index_16.png" alt="" />
      <div class="content">
        <div class="title">{{ globalData.index.strip.title }}</div>
        <div class="en">
          {{ globalData.index.strip.en }}
        </div>
        <div class="digital">
          <div
            class="item"
            v-for="(item, index) of globalData.index.strip.digital"
            :key="index"
          >
            <div class="num">{{ item.num }}</div>
            <div class="txt">{{ item.txt }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="apply">
      <block-title
        :title="globalData.index.apply.top.title"
        :en="globalData.index.apply.top.en"
        :sub="globalData.index.apply.top.sub"
      ></block-title>
      <div class="tabs">
        <div
          class="item"
          @click="applyCurrentIndex = index"
          v-for="(item, index) of apply"
          :key="index"
          :class="{ active: applyCurrentIndex === index }"
        >
          <img class="icon" :src="item.icon" alt="" />
          <img class="icon hover" :src="item.hicon" alt="" />
          {{ item.txt }}
        </div>
      </div>
      <div class="form">
        <input ref="name" class="name" type="text" placeholder="姓名" />
        <input ref="phone" class="phone" type="text" placeholder="手机号码" />
        <div class="button" @click="sendInfo">申请试用</div>
      </div>
    </div>
    <my-footer></my-footer>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import bg1 from "../asset/index/ic_index_15.png";
import bg2 from "../asset/index/ic_index_08.png";
import bg3 from "../asset/index/ic_index_09.png";
import bg4 from "../asset/index/ic_index_10.png";
import bg5 from "../asset/index/ic_index_11.png";
import bg6 from "../asset/index/ic_index_12.png";

import abimg1 from "../asset/index/ic_index_46.png";
import abimg2 from "../asset/index/ic_index_44.png";
import abimg3 from "../asset/index/ic_index_43.png";
import abimg4 from "../asset/index/ic_index_45.png";

import infoimg1 from "../asset/index/ic_index_23.png";
import infoimg2 from "../asset/index/ic_index_51.png";
import infoimg3 from "../asset/index/ic_index_52.png";
import infoimg4 from "../asset/index/ic_index_53.png";

import drivebg1 from "../asset/index/ic_index_54.png";
import drivebg2 from "../asset/index/ic_index_55.png";
import drivebg3 from "../asset/index/ic_index_56.png";
import drivebg4 from "../asset/index/ic_index_57.png";
import drivebg5 from "../asset/index/ic_index_58.png";

import casebg1 from "../asset/index/ic_index_17.png";
import casebg2 from "../asset/index/ic_index_18.png";
import casebg3 from "../asset/index/ic_index_19.png";
import casebg4 from "../asset/index/ic_index_20.png";

import swiperbg1 from "../asset/index/ic_index_32.png";
import swiperbg2 from "../asset/index/ic_index_64.png";
import swiperbg3 from "../asset/index/ic_index_65.png";
import swiperbg4 from "../asset/index/ic_index_66.png";
import swiperbg5 from "../asset/index/ic_index_67.png";

let plans = [];

export default {
  data() {
    return {
      banner: [bg1, bg2, bg3, bg4, bg5, bg6],
      abtabs: [abimg1, abimg2, abimg3, abimg4],
      infos: [infoimg1, infoimg2, infoimg3, infoimg4],
      drivebg: [drivebg1, drivebg2, drivebg3, drivebg4, drivebg5],
      casebg: [casebg1, casebg2, casebg3, casebg4],
      applyCurrentIndex: 0,
      abCurrentIndex: 0,
      driveCurrentIndex: 0,
      swiperCurrentIndex: 0,
    };
  },
  methods: {
    next() {
      if (this.swiperCurrentIndex < this.swiper?.length - 1) {
        this.swiperCurrentIndex++;
      } else {
        this.swiperCurrentIndex = 0;
      }
    },
    sendInfo() {
      this.$router.push({
        name: "get-plan",
        params: {
          name: this.$refs.name.value,
          mobile: this.$refs.phone.value,
          plan: plans[this.applyCurrentIndex],
        },
      });
    },
  },
  computed: {
    ...mapGetters(["globalData"]),
    drive() {
      return window.deepMerge(
        [
          {
            left: {
              img: require("@/asset/index/ic_index_59.png"),
              activeImg: require("@/asset/index/ic_index_41.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/index/ic_index_25.png"),
              activeImg: require("@/asset/index/ic_index_60.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/index/ic_index_40.png"),
              activeImg: require("@/asset/index/ic_index_61.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/index/ic_index_39.png"),
              activeImg: require("@/asset/index/ic_index_62.png"),
            },
            right: {},
          },
          {
            left: {
              img: require("@/asset/index/ic_index_38.png"),
              activeImg: require("@/asset/index/ic_index_63.png"),
            },
            right: {},
          },
        ],
        this.globalData.index.drive.list
      );
    },
    advantage() {
      return window.deepMerge(
        [
          { icon: require("@/asset/index/ic_index_28.png") },
          { icon: require("@/asset/index/ic_index_27.png") },
          { icon: require("@/asset/index/ic_index_47.png") },
          { icon: require("@/asset/index/ic_index_26.png") },
        ],
        this.globalData.index.advantage.right
      );
    },
    swiper() {
      return window.deepMerge(
        [
          {
            img: swiperbg1,
            en: "Yunnan Yunfeng Expressway",
            title: "云南云凤高速",
            brief:
              "“贵司提供的智慧城市安全监测软件解决方案在提高城市安全方面发挥了重 要作用。我们非常满意贵司的专业技术和优质服务，感谢你们的团队在项目实施过程中所做出的努力和贡献。”",
          },
          {
            img: swiperbg2,
            en: "Monitoring of Xiaoshiba collapse",
            title: "社区小石坝崩塌监测",
            brief:
              "“我们与贵公司合作的项目在智慧城市安全监测领域取得了显著成效。该软件不仅提高了城市的安全性，还为市民提供了更加便捷的生活服务。我们对贵司的专业能力和执行效率给予高度评价，期待未来有更多的合作机会。”",
          },
          {
            img: swiperbg3,
            en: "Kunming Monitoring of mining areas",
            title: "昆明经开区倪家营小石牛采矿区监测",
            brief:
              "“有效地提升了我们城市的应急响应速度和安全防范能力。感谢团队的专业服务和支持，希望未来能够继续保持紧密的合作关系。”",
          },
          {
            img: swiperbg4,
            en: "Monitoring of Nengyuan Quarry",
            title: "昆明经开区能原采石场监测",
            brief:
              "“我们深刻感受到了贵司的专业水准和服务态度。智慧城市安全监测软件不仅满足了我们的需求，还为城市的可持续发展提供了有力支持。我们再次感谢贵司为我们提供的优质产品和服务。”",
          },
          {
            img: swiperbg5,
            en: "Landslide monitoring in Sanwa Village",
            title: "高坡社区三瓦村滑坡监测",
            brief:
              "“该系统不仅具有高度的技术先进性和实用性，而且操作简便，为我们的管理工作带来了极大的便利。我们高度赞赏贵司的专业能力、技术实力和优质服务，期待未来有更多的合作机会。”",
          },
        ],
        this.globalData.index.confirm.swiper
      );
    },
    apply() {
      return window.deepMerge(
        [
          {
            icon: require("@/asset/index/ic_index_37.png"),
            hicon: require("@/asset/index/ic_index_69.png"),
          },
          {
            icon: require("@/asset/index/ic_index_24.png"),
            hicon: require("@/asset/index/ic_index_73.png"),
          },
          {
            icon: require("@/asset/index/ic_index_70.png"),
            hicon: require("@/asset/index/ic_index_36.png"),
          },
          {
            icon: require("@/asset/index/ic_index_35.png"),
            hicon: require("@/asset/index/ic_index_71.png"),
          },
          {
            icon: require("@/asset/index/ic_index_34.png"),
            hicon: require("@/asset/index/ic_index_72.png"),
          },
        ],
        this.globalData.index.apply.tabs
      );
    },
  },
  mounted() {
    plans = this.globalData.index.apply.tabs.map((v) => {
      return v.txt;
    });
  },
  destroyed() {},
};
</script>

<style scoped lang="scss">
.about {
  padding: 80px 0;
  .tabs {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
    .item {
      display: flex;
      justify-content: center;
      padding: 40px;
      background: white;
      flex-grow: 1;
      position: relative;
      cursor: pointer;
      gap: 16px;
      .img {
        width: 56px;
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title {
          font-size: 24px;
          color: #333333;
        }
        .brief {
          font-size: 16px;
          color: #666666;
        }
      }
    }
    .active {
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
        background: white;
      }
      &::after {
        content: "";
        position: absolute;
        width: 28px;
        height: 28px;
        bottom: 0;
        left: 50%;
        background: white;
        transform: translateX(-50%);
      }
    }
  }
}
.display {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 50px;
  .info {
    margin-top: 60px;
    padding: 60px;
    padding-right: 30px;
    border-left: 10px solid #dddddd;
    border-bottom: 10px solid #dddddd;
    .title {
      font-size: 24px;
      color: #4e99fd;
      margin-bottom: 30px;
    }
    .info-item {
      position: relative;
      font-size: 18px;
      color: #666666;
      line-height: 2;
      padding-left: 20px;
      &::after {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4e99fd;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .info-img {
    width: 622px;
  }
}
.drive {
  line-height: 1;
  padding: 80px 0;
  transition: 0.5s all;
  .en-title {
    font-size: 36px;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 10px;
    text-align: center;
  }
  .ch-title {
    font-size: 36px;
    color: white;
    margin-bottom: 34px;
    text-align: center;
    font-family: YouSheBiaoTiHei;
  }
  .ch-brief {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    margin-bottom: 80px;
  }
  .content {
    display: flex;
    height: 420px;
    .left {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border-right: 2px solid rgba($color: white, $alpha: 0.6);
      flex-shrink: 0;
      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-right: 40px;
        cursor: pointer;
        .item-icon {
          width: 20px;
        }
        .item-txt {
          font-size: 20px;
          font-weight: bold;
          color: white;
        }
      }
      .item-strip {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        width: 4px;
        height: 20%;
        transition: all 0.5s;
        margin-right: -1px;
        background: #4e99fd;
      }
    }

    .right {
      padding-left: 80px;
      .item {
        .title {
          font-size: 36px;
          color: white;
        }
        .brief {
          font-size: 20px;
          line-height: 2;
          margin: 40px 0;
          color: rgba($color: white, $alpha: 0.6);
        }
      }
    }
  }
}
.more-button {
  width: 217px;
  height: 61px;
  background: #4e99fd;
  border-radius: 2px 2px 2px 2px;
  font-size: 24px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  cursor: pointer;
  .more-icon {
    width: 30px;
  }
}
.advantage {
  padding: 80px 0;
  .content {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-top: 60px;

    .left {
      color: white;
      background: url(../asset/index/ic_index_21.png) no-repeat center/cover;
      padding: 70px 40px;
      width: 427px;
      height: 667px;
      box-sizing: border-box;
      flex-shrink: 0;
      .icon {
        width: 60px;
        margin-bottom: 40px;
      }
      .title {
        font-size: 28px;
        margin-bottom: 8px;
      }
      .en-title {
        font-size: 12px;
        margin-bottom: 40px;
        color: rgba(255, 255, 255, 0.8);
      }
      .brief {
        font-size: 16px;
        line-height: 2;
        color: rgba(255, 255, 255, 0.8);
      }
    }
    .right {
      display: flex;
      flex-wrap: wrap;
      width: 690px;
      gap: 24px;
      .item {
        padding: 40px;
        width: 328px;
        height: 320px;
        box-sizing: border-box;
        border: 1px solid #4e99fd;
        border-radius: 4px;
        .icon {
          height: 60px;
          margin-bottom: 40px;
        }
        .title {
          font-size: 28px;
          margin-bottom: 8px;
        }
        .en-title {
          font-size: 12px;
          margin-bottom: 40px;
          color: rgba(153, 153, 153, 0.8);
        }
        .brief {
          font-size: 16px;
          line-height: 2;
          color: #666666;
        }
      }
    }
  }
}
.case {
  padding: 80px 0;
  background: url(../asset/index/ic_index_68.png) no-repeat center/cover;
  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 24px;
    .item {
      width: 300px;
      height: 480px;
      position: relative;
      .hover {
        padding: 24px 40px;
        background: rgba($color: white, $alpha: 0.8);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        transition: all 0.5s;
        box-sizing: border-box;
        height: 104px;
        box-sizing: border-box;
        overflow: hidden;
        .title {
          color: #333333;
          font-size: 24px;
          margin-bottom: 16px;
        }
        .sub {
          color: #666666;
          font-size: 16px;
        }
        .brief {
          color: white;
          font-size: 16px;
          line-height: 2;
          margin-top: 40px;
          transition: 0.5s all;
        }
      }
      &:hover {
        .hover {
          height: 100%;
          background: rgba(78, 153, 253, 0.6);
        }
        .title,
        .sub {
          color: white;
        }
      }
    }
  }
}
.confirm {
  padding-top: 96px;
  .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;
    margin-top: 68px;
    .swiper-list {
      position: relative;
      height: 505px;
      flex-grow: 1;
      overflow: hidden;
      .swiper-item {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: 0.5s all;
        text-align: center;
        display: flex;
        align-items: flex-start;
        gap: 80px;
        .swiper-img {
          width: 960px;
          height: 505px;
        }
        .swiper-right {
          text-align: left;
          padding-right: 190px;
          .swiper-num {
            margin-bottom: 40px;
            .sp {
              font-size: 40px;
              color: #4e99fd;
              font-weight: bold;
            }
            .count {
              font-size: 18px;
              color: #dddddd;
            }
          }
          .swiper-en {
            color: #dddddd;
            font-size: 24px;
            position: relative;
            margin-bottom: 16px;
            display: inline-block;
            &::after {
              content: "";
              position: absolute;
              width: 80px;
              height: 4px;
              left: 100%;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 24px;
              background: #4e99fd;
            }
          }
          .swiper-title {
            font-size: 24px;
            color: #4e99fd;
            margin-bottom: 70px;
            font-weight: bold;
          }
          .swiper-brief {
            font-size: 18px;
            color: #666666;
            line-height: 2;
            margin-bottom: 30px;
            width: 500px;
          }
          .swiper-more {
            font-size: 18px;
            color: #4e99fd;
            gap: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .swiper-icon {
              width: 30px;
            }
          }
        }
      }
    }
    .next {
      flex-shrink: 0;
      position: absolute;
      right: 190px;
      top: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .indicator {
      position: absolute;
      bottom: 0;
      left: 1040px;
      display: flex;
      align-items: center;
      gap: 24px;
      .indicator-item {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #4e99fd;
        opacity: 0.2;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .indicator-item-active {
        opacity: 0.8;
      }
    }
  }
}
.strip {
  margin-top: 120px;
  position: relative;
  .bg {
    width: 100%;
  }
  .content {
    position: absolute;
    box-sizing: border-box;
    top: 27%;
    right: calc(((100% - 1920px) / 2) + 320px);

    .title {
      color: #333333;
      font-size: 36px;
      margin-bottom: 9px;
      font-family: YouSheBiaoTiHei;
      text-align: center;
    }
    .en {
      color: rgba(51, 51, 51, 0.4);
      font-size: 24px;
      margin-bottom: 80px;
      text-align: center;
    }
    .digital {
      display: flex;
      justify-content: center;
      gap: 140px;
      .item {
        .num {
          font-size: 48px;
          color: #4e99fd;
          font-weight: bold;
        }
        .txt {
          font-size: 18px;
          color: #666666;
          margin-top: 16px;
        }
      }
    }
  }
}

.apply {
  margin: 100px 0;
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    margin-bottom: 40px;
    margin-top: 80px;
    .item {
      width: 200px;
      height: 98px;
      display: flex;
      align-items: center;
      gap: 16px;
      justify-content: center;
      background: #ffffff;
      box-shadow: 3px 3px 8px 1px #e8e8e8;
      border-radius: 2px 2px 2px 2px;
      font-size: 24px;
      color: #333333;
      cursor: pointer;
      .icon {
        width: 32px;
      }
      .hover {
        display: none;
      }
      &:hover,
      &.active {
        background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
        color: white;
        .icon {
          display: none;
        }
        .hover {
          display: block;
        }
      }
    }
  }
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 70px;
    .name,
    .phone {
      font-size: 24px;
      border: 1px solid #dddddd;
      outline: none;
      height: 60px;
      padding: 0 28px;
      width: 470px;
      box-sizing: border-box;
    }
    .button {
      flex-shrink: 0;
      background: linear-gradient(180deg, #4e99fd 0%, #5482fa 100%);
      box-shadow: 3px 3px 8px 1px #e8e8e8;
      border-radius: 2px 2px 2px 2px;
      width: 200px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 24px;
      cursor: pointer;
    }
  }
}
</style>
