<template>
	<div class="card-list" :class="`styled-${list.length}`" :style="style">
		<card-item
			v-for="(item, i) in list"
			:key="i"
			:title="item.title"
			:description="item.description"
			:icon="item.icon"
			:content-width="item.contentWidth"
			:layout="item.layout || getLayout(i)"
			:style="item.style"
		></card-item>
	</div>
</template>

<script>
import CardItem from "./card-item.vue";
export default {
	name: "card-list",
	components: {
		CardItem
	},
	props: {
		list: {
			type: Array,
			default: () => []
		},
		gap: {
			type: [String, Number],
			default: 70
		},
		orientation:{
			type:String,
			default:''
		}
	},
	computed: {
		style() {

			const style = {
				gap: `${window.flexible.px2rem(this.gap)}rem`,
			}
			const reflect = {
				2: 2,
				3: 3,
				4: 2,
				5: 6,
				6: 3
			}
			const count = reflect[this.list.length];
			const each = (1 / count * 100).toFixed(2) + '%';
			const gap = window.flexible.px2rem(this.gap * (count - 1) / count) + 'rem';
			style.gridTemplateColumns = `repeat(${count}, calc(${each} - ${gap}))`;
			return style
		}
	},
	methods: {
		getLayout(i) {
			if(this.orientation){
				return this.orientation;
			}else{
				switch (this.list.length) {
				case 2: return 'x'
				case 3: return 'y'
				case 4: return 'x'
				case 5: return i > 2 ? 'x': 'y'
				case 6: return 'x'
			}
			}
			
		}
	}
}
</script>

<style scoped lang="scss">
.card-list {
	display: grid;
	&.styled-2 {
		grid-template-columns: repeat(2, 1fr);
	}
	&.styled-3 {
		grid-template-columns: repeat(3, 1fr);
	}
	&.styled-4 {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}
	&.styled-5 {
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(2, auto);
		& > div:nth-child(1),& > div:nth-child(2),& > div:nth-child(3) {
			grid-column: span 2;
		}
		& > div:nth-child(4),& > div:nth-child(5) {
			grid-column: span 3;
		}
	}
	&.styled-6 {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(2, auto);
	}
}
</style>
