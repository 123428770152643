<template>
  <div class="list content-limit">
    <div
      class="item"
      :style="{ background: `url(${item.img}) center/cover no-repeat` }"
      v-for="(item, index) of list"
      :key="index"
    >
      <div class="hover">
        <div class="name">{{ item.name }}</div>
        <div class="button" v-if="item.button" @click="item.onClick">{{ item.button }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: [],
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 80px;
  .item {
    width: calc(33.3% - 16px);
    position: relative;
    height: 266px;
    .hover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-content: center;
      justify-content: center;
      gap: 40px;
      background: rgba(51, 51, 51, 0.4);
      flex-wrap: wrap;
      text-align: center;
      .name {
        width: 100%;
        font-size: 24px;
        color: white;
      }
      .button {
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        color: white;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
