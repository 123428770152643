<template>
	<div class="smart-water-conservancy">
		<top-nav :img-list="banner">
			<div class="banner-item-title" style="text-align: center" v-html="static.banner.title"></div>
			<span class="banner-item-brief" style="width: 1280px;" v-html="static.banner.brief"></span>
		</top-nav>
		<div class="block-area sw-b2 pt-100px pb-80px">
			<div class="block-content">
				<div class="card-box flex space-between">
					<pin-card style="height: 121px;" v-for="t in block1" :key="t.title" :title="t.title" :icon="t.icon" :sub="t.sub"></pin-card>
				</div>
				<block-title style="margin-top: 80px;" :data="static.title1"></block-title>
				<img style="margin-top: 40px;" src="./assets/sw-b2.png" alt="">
			</div>
		</div>
		<div class="block-area sw-b3 pt-80px pb-80px">
			<block-title :data="static.title2"></block-title>
			<display-list style="margin-top: 70px;" :list="block2"></display-list>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import DisplayList from "../../components/display-list/index.vue";

export default {
	name: "index",
	components: { DisplayList, PinCard },
	data: function () {
		return {
			defines: {
				block1: [
					{
						title: '安全体系',
						sub: '护卫水利安全',
						icon: require('./assets/sw-b1-0.png')
					},
					{
						title: '标准体系',
						sub: '智能水利标准规范',
						icon: require('./assets/sw-b1-1.png')
					},
					{
						title: '运营体系',
						sub: '组织运作的规则相应资源',
						icon: require('./assets/sw-b1-2.png')
					},
				],
				block2: [
					{
						title: '中小水库雨水情测报和安全监测解决方案',
						description: '位置雨水情测报和安全监测系统，通过GIS一张图将各类信息概览无余，各水库安全运行状态尽收眼底。做到足不出户，就知雨水情。 平台主要包括地图综合展示、雨水情测报、安全监测、视频监控、预警管理、巡检养护、预报调度、数据管理、用户管理9个子系 统。平台可通过“一级部署多级应用”的方式进行分级管理，经一级雨水情测报和安全监测平台接收、处理后，向各级监测平台推送数据，进一步实现各级监测数据的实时共享和互联互通。\n\n水系监测系统   |   流量监测系统   |   液位监测系统   |   黑臭水体监测系统\n',
						icon: require('./assets/sw-b3-0.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-water-conservancy/security-monitoring')
						}
					},
					{
						title: '水利水电工程北斗/GNSS自动化变形监测解决方案',
						description: '置以北斗地基增强系统为统一时空基准，为客户提供北斗/GNSS监测终端及具备专利技术的数据解算系统，助力实现水利水电工程高精度、自动化的外观变形监测，减少人力投入，提升安全水平。\n\n抗干扰性好、自主可控，降低安全风险，且系统操作便捷、可维护性强。',
						icon: require('./assets/sw-b3-1.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-water-conservancy/reservoir-project')
						}
					},
					{
						title: '水环境智慧化管理解决方案',
						description: '按照“一舱（驾驶舱）、两端（治理端与服务端）、N场景”核心架构，构建水环境智慧化应用，对水环境进行立体化、高效化和智能化巡检，形成事件发现、派发、处置到反馈的全流程线上闭环处理，实现水环境智慧化管理。\n\n北斗+AI，精准治理，闭环管理，高效协同',
						icon: require('./assets/sw-b3-2.png'),
						btn: {
							label: '查看方案',
							onclick: () => this.$router.push('/smart-water-conservancy/water-environment')
						}
					}
				]
			}
		}
	},
	computed: {
		...mapGetters(["globalData"]),
		static() {
			return this.globalData['smart-water'].index
		},
		block1() {
			return window.deepMerge(this.defines.block1, this.static.block1)
		},
		block2() {
			return window.deepMerge(this.defines.block2, this.static.block2)
		},
		banner() {
			return require('./assets/smart-water-conservancy-banner.png')
		},
	},
	mounted () {
		console.log('/smart-water-conservancy');
	}
}
</script>

<style scoped lang="scss">
.smart-water-conservancy {
	.sw-b2 {
		img {
			object-fit: scale-down;
			margin: 0 auto;
			display: block;
			width: 100%;
			height: 773px;
		}
	}
	.sw-b3 {
		background: url("./assets/sm-b2.png") center no-repeat;
		background-size: cover;
	}
}
</style>
