<template>
  <div class="container">
    <top-nav :imgList="banner">
      <div class="banner-item-title">
        {{ globalData.smartPortResolution.title }}
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartPortResolution.brief1 }}<br />{{
          globalData.smartPortResolution.brief2
        }}
      </div>
    </top-nav>
    <div class="structure">
      <div class="box content-limit">
        <div class="item" v-for="(item, index) of structure" :key="index">
          <img class="icon" :src="item.img" alt="" />
          <div class="txt">{{ item.txt }}</div>
        </div>
      </div>
      <block-title
        :title="globalData.smartPortResolution.structure.top.title"
        :en="globalData.smartPortResolution.structure.top.en"
        :sub="globalData.smartPortResolution.structure.top.sub"
      ></block-title>
      <img
        class="content-limit img"
        src="@/asset/smart-port/ic_port_25.png"
        alt=""
      />
    </div>
    <div class="application">
      <block-title
        :title="globalData.smartPortResolution.application.top.title"
        :en="globalData.smartPortResolution.application.top.en"
        :sub="globalData.smartPortResolution.application.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block3" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="introduction content-limit">
      <block-title
        :title="globalData.smartPortResolution.introduction.top.title"
        :en="globalData.smartPortResolution.introduction.top.en"
        :sub="globalData.smartPortResolution.introduction.top.sub"
      ></block-title>
      <introduce-list style="margin-top: 80px" :list="block4"></introduce-list>
    </div>
    <footer-resolution
      :title="globalData.smartPortResolution.footer.title"
      :en="globalData.smartPortResolution.footer.en"
      :sub="globalData.smartPortResolution.footer.sub"
    ></footer-resolution>

    <my-footer></my-footer>
  </div>
</template>
  <script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import DisplayList from "../../components/display-list/index.vue";
import CardList from "../../components/card-list/index.vue";
import IntroduceList from "../../components/introduce-list/index.vue";

export default {
  data() {
    return {
      banner: require("@/asset/smart-port/ic_port_11.png"),

      advCurrentIndex: 0,
    };
  },
  components: {
    PinCard,
    DisplayList,
    CardList,
    IntroduceList,
  },
  computed: {
    ...mapGetters(["globalData"]),
    structure() {
      return window.deepMerge(
        [
          {
            img: require("@/asset/smart-port/ic_port_21.png"),
          },
          {
            img: require("@/asset/smart-port/ic_port_22.png"),
          },
          {
            img: require("@/asset/smart-port/ic_port_23.png"),
          },
          {
            img: require("@/asset/smart-port/ic_port_24.png"),
          },
        ],
        this.globalData.smartPortResolution.structure.list
      );
    },
    block3() {
      return window.deepMerge(
        [
          {
            title: "一体化集成",
            description: "打破港口作业信息孤岛、优化作业流程的“一体化”集成服务",
            icon: require("@/asset/smart-port/ic_port_31.png"),
          },
          {
            title: "灵活拓展",
            description:
              "融合港口已有系统或平台，多部门一个管理平台易于维护，无需重复建设，降低应用成本",
            icon: require("@/asset/smart-port/ic_port_29.png"),
          },
          {
            title: "安全环保",
            description:
              "减少了港口安全环保事件的发生概率，减少了港口内部以及港口外部市政道路拥堵的困扰",
            icon: require("@/asset/smart-port/ic_port_30.png"),
          },
        ],
        this.globalData.smartPortResolution.application.list
      );
    },

    block4() {
      return window.deepMerge(
        [
          {
            title: "港口调度管理",
            en: "Port dispatch management",
            description:
              "基于统一时空基准的全生产要素调度平台，支持全港生产资源动态调配，作业指令自动触发流转，提升港内资源利用效率",
            icon: require("@/asset/smart-port/ic_port_27.png"),
            height: 505,
          },
          {
            title: "港口安全管理",
            en: "Port security management",
            description:
              "支持港内设备、人员自动化、智能化监控，违规事件告警，时空智能与人工智能结合，预警、报警触发，事故追溯",
            icon: require("@/asset/smart-port/ic_port_26.png"),
            height: 505,
          },
          {
            title: "港口设施设备管理",
            en: "Port facility and equipment management",
            description:
              "提供设备数字化管控，设备位置查找，设备维护、设备关键节点提醒等功能",
            icon: require("@/asset/smart-port/ic_port_28.png"),
            height: 505,
          },
        ],
        this.globalData.smartPortResolution.introduction.list
      );
    },
  },
};
</script>
  <style scoped lang="scss">
.container {
}
.structure {
  padding: 100px 0 80px;
  .box {
    margin-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      text-align: center;
      .icon {
        width: 65px;
        margin-bottom: 8px;
      }
      .txt {
        color: #333333;
        font-size: 24px;
      }
    }
  }
  .img {
    width: 100%;
    display: block;
    margin-top: 40px;
  }
}
.application {
  background: url(@/asset/smart-port/ic_port_32.png) no-repeat center/cover;
  height: 666px;
  padding: 80px 0 40px;
  box-sizing: border-box;
}
.introduction {
  padding-top: 80px;
}
</style>