import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/index.vue';
import SmartPort from '@/views/smart-port/index.vue'
import PortResolution from '@/views/smart-port/port-resolution.vue'
import LocationResolution from '@/views/smart-port/location-resolution.vue'
import SmartTraffic from '@/views/smart-traffic/index.vue'
import SwiftResolution from '@/views/smart-traffic/swift-resolution.vue'
import PatrolResolution from '@/views/smart-traffic/patrol-resolution.vue'
import MonitorResolution from '@/views/smart-traffic/monitor-resolution.vue'
import EnforceResolution from '@/views/smart-traffic/enforce-resolution.vue'


Vue.use(VueRouter);


const routes = [
	{
		path: '/',
		component: Index,
	},
	{
		path: '/smart-port',
		component: SmartPort
	},
	{
		path: '/smart-port-resolution',
		component: PortResolution,
	},
	{
		path: '/smart-location-resolution',
		component: LocationResolution,
	},
	{
		path: '/smart-traffic',
		component: SmartTraffic,
	},
	{
		path: '/smart-swift-resolution',
		component: SwiftResolution,
	},
	{
		path: '/smart-patrol-resolution',
		component: PatrolResolution,
	},
	{
		path: '/smart-monitor-resolution',
		component: MonitorResolution,
	},
	{
		path: '/smart-enforce-resolution',
		component: EnforceResolution,
	},
	{
		path: '/product',
		component: require('@/views/product.vue'),
	},
	{
		path: '/service',
		component: require('@/views/service.vue'),
	},
	{
		name: 'get-plan',
		path: '/get-plan',
		component: require('@/views/get-plan.vue'),
	},
	{
		path: '/about',
		component: require('@/views/about.vue'),
	},


	{
		name: 'smart-water-conservancy',
		path: '/smart-water-conservancy',
		component: require('@/views/smart-water-conservancy/index.vue'),
	},
	{
		name: 'security-monitoring',
		path: '/smart-water-conservancy/security-monitoring',
		component: require('@/views/smart-water-conservancy/security-monitoring.vue')
	},
	{
		name: 'reservoir-project',
		path: '/smart-water-conservancy/reservoir-project',
		component: require('@/views/smart-water-conservancy/reservoir-project.vue')
	},
	{
		name: 'water-environment',
		path: '/smart-water-conservancy/water-environment',
		component: require('@/views/smart-water-conservancy/water-environment.vue')
	},
	{
		name: 'smart-quarry',
		path: '/smart-quarry',
		component: require('@/views/smart-quarry/index.vue')
	},
	{
		name: 'digital-quarry',
		path: '/smart-quarry/digital-quarry',
		component: require('@/views/smart-quarry/digital-quarry.vue')
	},
	{
		name: 'quarry-monitor',
		path: '/smart-quarry/quarry-monitor',
		component: require('@/views/smart-quarry/quarry-monitor.vue')
	},
	{
		name: 'car-monitor',
		path: '/smart-quarry/car-monitor',
		component: require('@/views/smart-quarry/car-monitor.vue')
	},
	{
		name: 'smart-city',
		path: '/smart-city',
		component: require('@/views/smart-city/index.vue')
	},
	{
		name: 'life-line',
		path: '/smart-city/life-line',
		component: require('@/views/smart-city/life-line.vue')
	},
	{
		name: 'bridge-monitor',
		path: '/smart-city/bridge-monitor',
		component: require('@/views/smart-city/bridge-monitor.vue')
	},
	{
		name: 'disaster-monitor',
		path: '/smart-city/disaster-monitor',
		component: require('@/views/smart-city/disaster-monitor.vue')
	},
	{
		name: 'pipe-gallery',
		path: '/smart-city/pipe-gallery',
		component: require('@/views/smart-city/pipe-gallery.vue')
	},
	{
		name: 'cooperation-case',
		path: '/cooperation-case',
		component: require('@/views/cooperation-case/index.vue')
	},
	{
		name: '404',
		path: '/404',
		component: require('@/views/notFound.vue')

	},
	{
		path: "*",
		redirect: '/404'
	}
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

let router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	next()
})

router.afterEach(() => {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth"
	})
})

export default router
