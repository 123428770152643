<template>
  <div class="container">
    <top-nav :imgList="banner" isParent>
      <div class="banner-item-title">
        {{ globalData.smartTraffic.txt
        }}<span class="banner-item-sp">{{ globalData.smartTraffic.sp }}</span>
      </div>
      <div class="banner-item-brief">
        {{ globalData.smartTraffic.brief1 }}
        <br />
        {{ globalData.smartTraffic.brief2 }}
      </div>
    </top-nav>
    <div class="structure">
      <div class="card-box flex space-between content-limit">
        <pin-card
          v-for="t in block1"
          :key="t.title"
          :title="t.title"
          :icon="t.icon"
          :sub="t.sub"
        ></pin-card>
      </div>
      <block-title
        :title="globalData.smartTraffic.structure.top.title"
        :en="globalData.smartTraffic.structure.top.en"
        :sub="globalData.smartTraffic.structure.top.sub"
      ></block-title>
      <img
        class="content-limit img"
        src="@/asset/smart-traffic/ic_traffic_05.png"
        alt=""
      />
    </div>
    <div class="system">
      <block-title
        :title="globalData.smartTraffic.system.top.title"
        :en="globalData.smartTraffic.system.top.en"
        :sub="globalData.smartTraffic.system.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block3" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="advantage">
      <block-title
        :title="globalData.smartTraffic.advantage.top.title"
        :en="globalData.smartTraffic.advantage.top.en"
        :sub="globalData.smartTraffic.advantage.top.sub"
      ></block-title>
      <div class="content-limit">
        <card-list :list="block4" style="margin-top: 80px"></card-list>
      </div>
    </div>
    <div class="service">
      <block-title
        :title="globalData.smartTraffic.service.top.title"
        :en="globalData.smartTraffic.service.top.en"
        :sub="globalData.smartTraffic.service.top.sub"
      ></block-title>
      <display-list style="margin-top: 70px" :list="block2"></display-list>
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PinCard from "../../components/card-list/pin-card.vue";
import DisplayList from "../../components/display-list/index.vue";
import CardList from "../../components/card-list/index.vue";

export default {
  computed: {
    ...mapGetters(["globalData"]),
    block1() {
      return window.deepMerge(
        [
          {
            title: "违法取证书难",
            sub: "违法取证质量较低，数据利用率低",
            icon: require("@/asset/smart-traffic/ic_traffic_02.png"),
          },
          {
            title: "审核任务重",
            sub: "审核任务重，导致大量图片丢弃",
            icon: require("@/asset/smart-traffic/ic_traffic_03.png"),
          },
          {
            title: "行为识别不及时",
            sub: "违法事故频发，加剧城市拥堵",
            icon: require("@/asset/smart-traffic/ic_traffic_04.png"),
          },
        ],
        this.globalData.smartTraffic.structure.block1
      );
    },
    block3() {
      return window.deepMerge(
        [
          {
            title: "数据标准体系架构",
            description:
              "制定大数据标准体系、发布实施大 数据标准体系、修订完善大数据标 准体系、构建数据标准管理工具",
            icon: require("@/asset/smart-traffic/ic_traffic_06.png"),
          },
          {
            title: "标准体系目录",
            description:
              "目录体系规划、目录体系管理、目录注册管理、目录发布管理、目录维护管理、 目录资源申请管理、目录查询管理",
            icon: require("@/asset/smart-traffic/ic_traffic_07.png"),
          },
          {
            title: "数据管理规范",
            description: "主要包括交通运输数据采集管理和交通运输数据治理管理",
            icon: require("@/asset/smart-traffic/ic_traffic_08.png"),
          },
          {
            title: "运行监测管理规范",
            description:
              "对涉及的危货车辆监测、客运车辆监测、普货车 辆监测、出租车监测、公交车监测、人员状态监 测、车辆报警监测、重点场地监测等各个方面进 行规范管理，科学化、定量化分析、预报综合交 通运行的基本情况及其未来走势",
            icon: require("@/asset/smart-traffic/ic_traffic_09.png"),
          },
          {
            title: "行业信息发布规范",
            description:
              "对信息发布内容、格式，以 及图片、文字、音频等信息 进行规范，制定审批流程和 制度。",
            icon: require("@/asset/smart-traffic/ic_traffic_10.png"),
          },
        ],
        this.globalData.smartTraffic.structure.block3
      );
    },
    block4() {
      return window.deepMerge(
        [
          {
            title: "分布架构、自主算法",
            description:
              "完全自主知识产权的深度学习算法，提供高识别率、高效率的算法引擎应用",
            icon: require("@/asset/smart-traffic/ic_traffic_11.png"),
          },
          {
            title: "海量算法、自由组合",
            description: "数百种算法，且可根据场景优化算法效果",
            icon: require("@/asset/smart-traffic/ic_traffic_12.png"),
          },
          {
            title: "AI+智慧交通",
            description:
              "利用AI云计算、物联网、大数据、地理信息等技术，以需求为导向，以实用性、易用性为目标，统筹规划、整体布局的智慧交通平台",
            icon: require("@/asset/smart-traffic/ic_traffic_13.png"),
          },
          {
            title: "核心技术优势",
            description:
              "自主研发的语音识别和图像识别等核心技术，在自然语言处理和图像分类预测等方面具备核心优势",
            icon: require("@/asset/smart-traffic/ic_traffic_14.png"),
          },
          {
            title: "丰富的服务接入方式",
            description:
              "WebAPI、Android SDK、iOS SDK、Linux SDK、Java SDK等接入方式助您以较小的开发成本快速接入语音服务",
            icon: require("@/asset/smart-traffic/ic_traffic_15.png"),
          },
          {
            title: "稳定可靠的服务支撑",
            description:
              "先进的云计算、大数据技术为语音服务的稳定运行全程护航轻松应对亿万级别用户服务",
            icon: require("@/asset/smart-traffic/ic_traffic_16.png"),
          },
        ],
        this.globalData.smartTraffic.advantage.block4
      );
    },

    block2() {
      return window.deepMerge(
        [
          {
            title: "交通数据中台",
            description:
              "结合交通行业落地实施经验，形成交通数据从汇聚、开发、治理到服务的一整套数据使用机制和数据中台体系，提供交通一站式数据资产管理、数据服务和分析应用等平台能力，形成业务级的能力复用体系，赋能交通业务场景。",
            icon: require("@/asset/smart-traffic/ic_traffic_17.png"),
            btn: {
              label: "在线咨询",
              onclick: () => console.log("在线咨询"),
            },
          },
          {
            title: "语音指挥调度",
            description:
              "针对警情处置效率低、指挥调度操作繁琐等问题，利用语音识别、语音合成、语义理解等语音技术，提供更友好、更便捷的人机交互手段，解放交警双手，提升指挥调度和执勤执法工作效率。",
            icon: require("@/asset/smart-traffic/ic_traffic_18.png"),
            btn: {
              label: "在线咨询",
              onclick: () => console.log("在线咨询"),
            },
          },
          {
            title: "设备智慧运维",
            description:
              "通过对卡口、电警、视频、信号机、诱导屏等各类交通设备统一接入管理，实现对前端设备以及运行支撑环境的可视、可管、可控，为交通超脑提供强有力的运行服务保障。",
            icon: require("@/asset/smart-traffic/ic_traffic_19.png"),
            btn: {
              label: "在线咨询",
              onclick: () => console.log("在线咨询"),
            },
          },
          {
            title: "交通态势感知",
            description:
              "以交警自有数据和互联网数据为核心，通过大数据、人工智能等先进技术，实现城市路况智能感知、交通参数全面监测、交通流量精细分析、交通指数科学发布。",
            icon: require("@/asset/smart-traffic/ic_traffic_20.png"),
            btn: {
              label: "在线咨询",
              onclick: () => console.log("在线咨询"),
            },
          },
        ],
        this.globalData.smartTraffic.service.block2
      );
    },
  },
  data() {
    return {
      banner: require("@/asset/smart-traffic/ic_traffic_01.png"),
    };
  },
  components: {
    PinCard,
    DisplayList,
    CardList,
  },
};
</script>
<style lang="scss" scoped>
.structure {
  padding: 100px 0 80px;
  .card-box {
    margin-bottom: 80px;
  }
  .img {
    display: block;
    margin-top: 40px;
  }
}
.system {
  padding: 80px 0 40px;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat
    center/cover;
}
.advantage {
  padding: 80px 0;
}
.service {
  padding: 80px 0;
  background: url(@/asset/smart-traffic/ic_traffic_21.png) no-repeat
    center/cover;
}
</style>